import React from 'react';
import { Box, Container, Flex, Heading, Text } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import type { UI } from '@/v2/types';
import Link from '@/v2/components/ui/Link';
import Logo from '@/v2/components/ui/Logo';
import { useNavigation } from '@/v2/contexts/navigation';
import { useAppSettings } from '@/v2/../components/AppSettingsProvider';
import GoToTop from '../GoToTop/GoToTop';

const DestinationLinks = () => {
  // need to determine if legacy/v2 context
  const app = useAppSettings() as {
    pageHeader: UI.Navigation[];
  };
  const nav = useNavigation();
  const pageHeader = app.pageHeader.length > 0 ? app.pageHeader : nav!;

  const columns = pageHeader[0]?.columns as Array<UI.NavigationGroup[]>;

  const StyledLink = styled(Link.Text)`
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  `;

  const LogoLink = styled(Link.Text)`
    line-height: 0;
  `;

  return (
    <Box bg="greys.charcoal" borderTop="4px solid" borderColor="brand.primary">
      <Container>
        <Flex justifyContent="space-between" alignItems="flex-end">
          <LogoLink href="/">
            <Logo variant="light" />
          </LogoLink>
          <GoToTop />
        </Flex>
        <Heading.h4 textAlign="left" mt="8" color="white" mb="0">
          Qantas Holidays package destinations
        </Heading.h4>
        <Flex
          justifyContent="space-between"
          flexDirection={['column', 'row', 'row']}
        >
          {columns.map((column, i) => (
            <Flex
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              flexDirection="column"
              flex="1"
              mr={columns.length - 1 !== i ? 13 : 0}
            >
              {column.map(({ title, links, key: columnKey }) => (
                <Flex key={columnKey} flexDirection="column">
                  <Text mt="6" mb="2" fontWeight="bold" color="white">
                    {title}
                  </Text>
                  {links.map(({ url, text, key: linkKey }) => (
                    <StyledLink
                      variant="light"
                      fontSize="sm"
                      href={url}
                      key={linkKey}
                      py="1"
                    >
                      {text}
                    </StyledLink>
                  ))}
                </Flex>
              ))}
            </Flex>
          ))}
        </Flex>
      </Container>
    </Box>
  );
};

export default DestinationLinks;
