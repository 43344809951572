import React from 'react';
import { Box, Container, Flex, Hide } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { themeGet } from 'styled-system';
import { useRouter } from 'next/router';
import Link from '@/components/Link';
import { useAppSettings } from '../AppSettingsProvider';
import Item from './components/Item';
import getPathsArrayFromNavigation from './utils/getPathsArrayFromNavigation';
import { useNavigation } from '@/v2/contexts/navigation';
import dataLayer from '@/v2/utils/dataLayer';
import { useGA4Events } from '@/v2/hooks/featureFlags/ga4Events/useGA4Events';

const StyledBox = styled(Box)`
  border-bottom: 3px solid transparent;

  ${(props) =>
    props.active &&
    css`
      border-bottom-color: ${themeGet('colors.ui.link')(props)};
    `}

  &:hover {
    background-color: ${themeGet('colors.white')};
    border-top: 1px solid ${themeGet('colors.greys.alto')};
    margin-top: -1px;

    div {
      color: ${themeGet('colors.brand.primary')};
    }
  }
`;

const PageHeader = () => {
  const shouldUseNewEvents = useGA4Events();
  const { asPath } = useRouter();

  // need to determine if legacy/v2 context
  const app = useAppSettings();
  const nav = useNavigation();
  const pageHeader = app.pageHeader.length > 0 ? app.pageHeader : nav;

  const onClick = (text, url, index) => {
    if (shouldUseNewEvents) {
      dataLayer.menuEvent({ text, url, index });
    }
  };

  return (
    <Hide xs sm>
      <Box bg="greys.charcoal" color="white">
        <Container gutter="0">
          <Flex>
            {pageHeader.map((navigation, index) => (
              <StyledBox
                key={navigation.key}
                active={getPathsArrayFromNavigation(navigation).includes(
                  asPath,
                )}
                data-testid={navigation.text}
              >
                {navigation.url ? (
                  <Link.Text
                    p="4"
                    href={navigation.url}
                    onClick={() =>
                      onClick(navigation.text, navigation.url, index)
                    }
                  >
                    <Box color="white">{navigation.text}</Box>
                  </Link.Text>
                ) : (
                  <Item columns={navigation.columns} onClick={onClick}>
                    {navigation.text}
                  </Item>
                )}
              </StyledBox>
            ))}
          </Flex>
        </Container>
      </Box>
    </Hide>
  );
};

export default PageHeader;
