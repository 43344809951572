import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Heading,
  Image,
  Paragraph,
  Container,
  Box,
} from '@qga/roo-ui/components';
import Link from '@/components/Link';
import alert from './assets/alert.svg';
import DestinationLinks from '@/v2/components/ui/SiteFooter/components/DestinationLinks';

const MaintenanceView = (props) => {
  const { title, message, contact } = props.maintenanceMessage;

  return (
    <Box bg="white">
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        flex="1"
        textAlign="center"
        px="5"
        py="10"
        minHeight="500px"
      >
        <Image height="80px" src={alert} alt="Alert" />
        <Heading.h2 mt="2">{title}</Heading.h2>
        <Paragraph mb="6">{message}</Paragraph>
        {contact && (
          <Link.Button variant="primary" href="/contact-us">
            CONTACT QANTAS HOLIDAYS TEAM
          </Link.Button>
        )}
      </Flex>
      <DestinationLinks />
    </Box>
  );
};

MaintenanceView.propTypes = {
  maintenanceMessage: PropTypes.any,
};

export default MaintenanceView;
