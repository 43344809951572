import { flatten } from 'ramda';

const getPathsArrayFromNavigation = (navigation) => {
  if (navigation.url) {
    return [navigation.url];
  }

  return flatten(
    flatten(navigation.columns?.map((c) => c)).map((x) =>
      x.links.map((link) => link.url),
    ),
  );
};

export default getPathsArrayFromNavigation;
