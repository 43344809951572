import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex, Icon, NakedButton } from '@qga/roo-ui/components';
import Link from '@/components/Link';
import ClickOutside from '@/components/ClickOutside';
import Dropdown from '@experiences-ui/shared/components/Dropdown';
import { Columns } from '@/propTypes/PageHeader';
import { flatten } from 'ramda';

const Button = styled(NakedButton)`
  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const Item = ({ children, columns, onClick }) => {
  const flatLinks = columns
    .flatMap((items) => items)
    .flatMap(({ links }) => links);

  return (
    <Dropdown
      toggle={(show, ref, { isShown, hide }) => (
        <Box ref={ref}>
          <Button
            data-testid="TOGGLE"
            onMouseEnter={(event) => {
              event.currentTarget.focus();
              show();
            }}
            onMouseLeave={hide}
            onClick={show}
          >
            <Flex
              data-testid="TOGGLER"
              p="4"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...(isShown && {
                color: 'brand.primary',
                bg: 'white',
                mt: '-1px',
                borderTop: '1',
                borderColor: 'greys.alto',
              })}
            >
              <Box mr="2">{children}</Box>
              <Icon name="expandMore" />
            </Flex>
          </Button>
        </Box>
      )}
      content={(hide, { show }) => (
        <ClickOutside onOutsideClick={hide}>
          <Flex
            onMouseEnter={show}
            onMouseLeave={hide}
            data-testid="CONTENT"
            bg="white"
            boxShadow="0px 10px 15px rgba(0,0,0,0.1)"
            py="4"
          >
            {columns.map((column, i) => (
              <Box
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                width="260px"
                px="8"
                borderRight={i < columns.length - 1 ? '1px solid' : 'none'}
                borderColor="greys.alto"
              >
                {column.map(({ title, links, key: columnKey }) => (
                  <Flex key={columnKey} flexDirection="column">
                    {title && (
                      <Box fontSize="xs" color="greys.steel" mt="6" mb="2">
                        {title}
                      </Box>
                    )}
                    {links.map(({ url, text, key: linkKey }) => (
                      <Box key={linkKey} my="2" onClick={hide}>
                        <Link.Text
                          href={url}
                          color="greys.charcoal"
                          onClick={() => {
                            onClick(
                              text,
                              url,
                              flatLinks.findIndex((item) => item?.url === url),
                            );
                            hide();
                          }}
                        >
                          {text}
                        </Link.Text>
                      </Box>
                    ))}
                  </Flex>
                ))}
              </Box>
            ))}
          </Flex>
        </ClickOutside>
      )}
    />
  );
};

Item.propTypes = {
  children: PropTypes.node.isRequired,
  columns: Columns.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Item;
