import PropTypes from 'prop-types';

const Link = PropTypes.shape({
  external: PropTypes.bool,
  text: PropTypes.string,
  url: PropTypes.string,
});

const Columns = PropTypes.arrayOf(
  PropTypes.arrayOf(
    PropTypes.shape({
      links: PropTypes.arrayOf(Link),
      type: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
);

const PageHeader = PropTypes.arrayOf(
  PropTypes.oneOfType([
    Link,
    PropTypes.shape({
      columns: Columns,
    }),
  ]),
);

export { Columns, PageHeader };
